<template>
  <div>
    <el-card shadow="never">
      <el-form label-position="right"
               :model="formData"
               ref="feedbackForm"
               label-width="65px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-form-item label="标题：" prop="title">
          {{formData.title}}
        </el-form-item>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="封面：" prop="cover">
              <img :src="formData.cover" style="width: 300px;" alt="">
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型：" prop="type">
<!--              </el-select>-->
              {{this.formData.type | policyType}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="发布者：" prop="publisher">
              {{formData.publisher}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序号：" prop="orderNo">
              {{formData.orderNo}}
            </el-form-item>
          </el-col>
        </el-row>
        <div class="areaSplit">
          <el-form-item label="内容：" prop="content">
            <div v-html="formData.content"></div>
          </el-form-item>
        </div>
        <div class="areaSplit">
          <el-form-item label="内容链接：" prop="url">
            <div v-html="formData.url"></div>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "policyDetail",
  data() {
    return {
      id: null,
      formData: {}
    }
  },
  created() {
    this.id = this.$route.params.policyId
    this.tryGetPolicyById()
  },
  methods: {
    ...mapActions(['getPolicyById']),
    tryGetPolicyById() {
      this.getPolicyById(this.id).then(res => {
        console.log(res)
        this.formData = res
      })
    }
  }
}
</script>

<style scoped>
.areaSplit {
  border-top: 1px solid #909399;
  padding-top: 15px;
}
</style>